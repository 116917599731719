import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'
import PageHelmet from '../components/pageHelmet'

import forBizHeroImg from '../images/hero-grocery-store.jpg'
import forBizSvg from '../images/for-businesses.svg'
import benefitsSVG from '../images/benefits-desktop.svg'
import getStartedSVG from '../images/get-started.svg'
import toolkitTeaserImg from '../images/toolkit-teaser@2x.png'

const winWin = (
  <p>
    What's in it for me? This is a <strong>win-win solution</strong>, reducing
    the chance of employees spreading the virus in the workplace and to others.
    In turn, you are providing comfort to your customers, encouraging them to return due to your ‘COVID-Conscious’ environment. <a href="/get-started">Sign up your business now</a>.
  </p>
)

const ForBusinessesPage = () => (
  <Layout>
    <PageHelmet
      title="For Businesses"
      description="Give customers confidence to patronize your business; knowing it’s following our three simple ‘Covid-Conscious’ practices of masks, hygiene, and health."
      image={forBizHeroImg} />
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'FOR'}
      accentedTitle={'Businesses'}
      subtitle={
        'Businesses are joining together to improve their community’s wellness'
      }
    />
    <section className="section mow-border-bottom" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">{winWin}</div>
        </div>
        <div className="level">
          <div className="level-item">
            <img src={benefitsSVG} className="business-benefits" />
          </div>
        </div>
      </div>
    </section>

    <section className="section mow-border-bottom secondary-toolkit">
      <div className="divider-title">
        <h3>Free Toolkit</h3>
      </div>
      <div className="columns is-marginless v-align-center">
        <div className="column square-bg-biz-toolkit">
        </div>
        <div className="column home-half-text-left">

            <h2><br/>Sign Up</h2>
            <h5>For 'Covid-Conscious' Businesses</h5>
            <p>Give customers confidence to patronize your business; knowing it’s following our three simple ‘Covid-Conscious’ practices of masks, hygiene, and health. Our downloadable toolkit includes:
              </p>
              <div className="content">
                <ul>
                  <li>2 - 8.5x11in business flyers</li>
                  <li>4x4in square printable paper door cling</li>
                  <li>Digital graphics to put on your website</li>
                  <li>Daily health employee sign in sheet example</li>
                  <li>Posts and hashtags for social media and digital marketing</li>
                </ul>
              </div>
              <p>Registering only takes 1 minute and benefits business <b>for a lifetime!</b>
              </p>
          <Link to="/get-started/">
              <button className="button">SIGN UP</button>
            </Link>
        </div>
    </div>
    </section>

    <section
      className="section resources"
      key="mow-forbiz-resources"
    >
      <div className="section-banner">
        <div className="container has-text-centered">
          <div className="section-heading-banner">
            <h3 className="title is-2 has-text-primary">RESOURCES</h3>
            <h5 className="title is-5 has-text-black">
              FREE RESOURCES FOR ALL BUSINESSES
            </h5>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="columns">
          <div className="column">
            <h6>MASKS</h6>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/your-health/effective-masks.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <b>New Article:</b> Improve How Your Mask Protects You
                  </a>{' '}
                  - CDC, Feb 2021
                </li>
                <li>
                  <a
                    href="https://www.cdc.gov/mmwr/volumes/70/wr/mm7007e1.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <b>New Article:</b> Maximizing Fit for Cloth and Medical Procedure Masks
                  </a>{' '}
                  - CDC, Feb 2021
                </li>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/DIY-cloth-face-covering-instructions.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article: DIY Cloth Face Coverings
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://youtu.be/tPx1yqvJgf4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Video: How to Make Your Own Face Covering
                  </a>{' '}
                  - Surgeon General
                </li>
                <li>
                  <a
                    href="https://www.forbes.com/sites/forbes-personal-shopper/2020/04/20/these-face-masks-can-help-stop-the-spread-of-the-coronavirus-and-theyre-available-right-now/#e666e693936b"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article: These Masks Can Help Stop The Spread and Are
                    Available Now
                  </a>{' '}
                  - Forbes
                </li>
                <li>
                  <a
                    href="https://bit.ly/stayhealthyyoutube"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Video: How Droplets Spray From The Simple Phrase "Stay Healthy" Without A Mask
                  </a>{' '}
                  - 'Mask of Wellness' YouTube
                </li>
                <li>
                  <a
                    href="https://bit.ly/masksforallMOW"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Video: ER Doc's Perspective on CDC's Mask Guidelines
                  </a>{' '}
                  - 'Mask of Wellness' YouTube
                </li>
                <li>
                  <a
                    href="https://youtu.be/5oLIZo8oGNI"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Video: COVID-19 - Easy to understand perspective from an Emergency Physician
                  </a>{' '}
                  - Dr. Dave & You YouTube
                </li>
              </ul>
            </div>
          </div>
          <div className="column">
            <h6>HYGIENE</h6>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/community/disinfecting-building-facility-H.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Disinfecting a Building Facility
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://www.epa.gov/pesticide-registration/list-n-disinfectants-use-against-sars-cov-2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article: List of Disinfectants to Use for COVID
                  </a>{' '}
                  - EPA
                </li>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fcoronavirus%2F2019-ncov%2Fprepare%2Fcleaning-disinfection.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article: Cleaning Disinfection
                  </a>{' '}
                  - CDC
                </li>
              </ul>
            </div>
          </div>
          <div className="column">
            <h6>HEALTH</h6>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/guidance-small-business.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Guidance for Small Businesses
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://www1.nyc.gov/assets/doh/downloads/pdf/imm/novel-coronavirus-faq-for-businesses.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Coronavirus FAQ for Businesses
                  </a>{' '}
                  - nyc.gov
                </li>
                <li>
                  <a
                    href="https://www.osha.gov/Publications/OSHA3990.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Guidance for Preparing Workplaces for COVID-19
                  </a>{' '}
                  - OSHA
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      className="section resources mow-border-top"
      key="mow-forbiz-resources"
    >
      <div className="section-banner">
        <div className="container has-text-centered">
          <div className="section-heading-banner">
            <h3 className="title is-2 has-text-primary">OPERATIONS</h3>
            <h5 className="title is-5 has-text-black">
              RESOURCES TO OPERATE AND STAY OPEN
            </h5>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="columns">
          <div className="column">
            <h6>REOPENING</h6>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/community/reopen-guidance.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article: CDC Reopen Guide
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://www.business.com/articles/covid-return-to-work-small-business/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article: Business.com Returning to Work for Small Businesses
                  </a>{' '}
                  - CDC
                </li>
              </ul>
            </div>
          </div>
          <div className="column">
            <h6>STATE & FEDERAL</h6>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://advocacy.sba.gov/2020/04/01/u-s-department-of-labor-announces-new-paid-sick-leave-and-expanded-family-and-medical-leave-implementation/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Sick Leave US Dept of Labor
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://www.fda.gov/media/136812/download"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: FDA Summary of Best Practices
                  </a>{' '}
                  - nyc.gov
                </li>
                <li>
                  <a
                    href="https://www.dol.gov/sites/dolgov/files/WHD/posters/FFCRA_Poster_WH1422_Non-Federal.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Dept of Labor Paid Sick Leave and Expanded Family and Medical Leave Act under CARES act
                  </a>{' '}
                  - OSHA
                </li>
              </ul>
            </div>
          </div>
          <div className="column">
            <h6>ACCESSIBILTY</h6>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="../downloads/DHH-Mask-Project-and- Tutorial-for-masks-with-windows.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Creating Masks with Windows
                  </a> - Deaf and Hard of Hearing Mask Project & Dr. Schaal Sampson, AuD {' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      className="section is-paddingless is-marginless mow-border-top"
      key="mow-forbiz-getstarted"
    >
      <div className="container-fluid">
        <div className="columns footer-cta-section is-marginless">
          <div className="column is-3 has-background-primary footer-block">
            <img src={getStartedSVG} className="footer-get-started" />
          </div>
          <div className="column is-8 sign-up-col">
            <h5 className="title is-4">SIGN UP YOUR BUSINESS</h5>
            <p>
              Our collaborative efforts WILL play a role in preventing COVID-19 reoccurrences and spikes. Consumers will seek out ‘Covid-Conscious’ Businesses thus benefiting the Health, Wealth, and Wellness of Individuals and Businesses in all of OUR communities.
            </p>
            <Link to="/get-started/">
              <button className="button">SIGN UP</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ForBusinessesPage
